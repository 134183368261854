@media (max-width: 320px) {
  .hidden-xs-portrait{
    display: none !important;
  }
}


@media (max-width: $screen-xs-max){
  .page-title{
    margin-top: 6px;
  }

  .nv-controlsWrap{
    display: none;
  }

  .logo {
    display: block;
    left: 1.6%;
    right: auto;
    padding-right: 5px;
    width: 40px;
    top: 4px;
  }



  .mailbox{
    .folder-title{
      float: none;
    }
    .form-search{
      margin-top: calc($line-height-computed / 2);
      label{
        display: block;
      }

      .input-search{
        width: 100%;
        height: 30px;
        @include box-sizing(border-box);
        &:hover{
          width: 100%;
        }
      }
    }
  }

  .mailbox-content{
    margin-top: 0;
  }

  .client-details{
    text-align: left;
  }

  .landing{
    .logo{
      width: auto;

      small{
        display: none;
      }
    }

    .features{
      + .features{
        margin-top: 0;
      }
    }

    .feature{
      margin-bottom: 20px;
    }
  }

  .carousel-landing{
    height: 215px;
    margin-bottom: -24px;
    h1{
      font-size: 20px;
    }
  }
}

@media (max-width: $screen-xs-min){

  .form-condensed .controls{
    margin-left: 0;
  }

// Remove the horizontal form styles
  .form-horizontal {
    .control-label {
      float: none;
      width: auto;
      padding-top: 0;
      text-align: left;
    }
    // Move over all input controls and content
    .controls {
      margin-left: 0;
    }
    // Move the options list down to align with labels
    .control-list {
      padding-top: 0; // has to be padding because margin collaspes
    }
    // Move over buttons in .form-actions to align with .controls
    .form-actions {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  body{
    padding-left: 0;
    padding-right: 0;
  }



  .page-header .search-query{
    @include input-block-level();
    &:focus{
      width: 100%;
    }
  }

  .visible-phone-landscape{
    display: block !important;
  }

  .hidden-phone-landscape{
    display: none !important;
  }
}
