@mixin bold-font{
  font-weight: $font-weight-base;
  color: $text-bold;
}

@mixin invalid-border {
  border-color: $orange;
  @include box-shadow(inset 0 1px 1px rgba($red,.075));
}

@mixin sidebar-settings{
  .settings{
    .btn-sm{
      padding: 2px 8px;
    }
  }
}

// Block level inputs
@mixin input-block-level {
  display: block;
  width: 100%;
  min-height: $input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
  @include box-sizing(border-box); // Makes inputs behave like true block-level elements
}

// Generates single property classes
// -------------------------
@mixin property-variants($base, $property, $variants: ()){
  @each $size, $value in $variants {
    @if $size == 'md'{
      #{$base} {
        #{$property}: $value;
      }
      #{$base}-n {
        #{$property}: $value;
      }
    }
    #{$base}-#{$size} {
      #{$property}: $value;
    }
    #{$base}-n-#{$size} {
      #{$property}: -$value;
    }
  }
}


@mixin keyframes($name){
  @-webkit-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

// Classes to be applied for high ppi displays
// -------------------------
@mixin retina(){
  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    @content;
  }
}

// Common thumbnail properties
// -------------------------
@mixin thumb($parent, $size){
  #{$parent} {
    width: $size;

    > .glyphicon,
    > .fa{
      line-height: $size;
    }
  }
}

// Linear gradient to transparent
// ------------------------------
@mixin gradient-horizontal-trans($position, $color){
    background: -webkit-linear-gradient($position, rgba(255,0,0,0) 0%, $color 21%); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient($position, rgba(255,0,0,0) 0%, $color 21%); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient($position, rgba(255,0,0,0) 0%, $color 21%); /* For Firefox 3.6 to 15 */
    background: linear-gradient(to $position, rgba(255,0,0,0) 0%, $color 21%); /* Standard syntax (must be last) */
}