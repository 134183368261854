.preserve-spaces {
    white-space: nowrap;
}
#time-report-table {
    .time-reports-table {
        thead th.static-row {

            &:nth-child(6n), &:nth-child(6n-1), &:nth-child(6n+1) {
                background: $orange;
            }
            &:nth-child(6n-3), &:nth-child(6n-4), &:nth-child(6n-2) {
                background: $red;
            }
        }
    }
    th, td {
        &.static-row {
            width: 160px;
        }
        &.scrolled-row {
            width: 300px;
        }
        &.scrolling {
            display: block;
            position: absolute;
            width: 300px;
            left: 16px;
            @include gradient-horizontal-trans(to right, $darkBlue);
        }
    }
}

/* Table Alts */
table {
    &.agreement-table {
        & .long-title {
            font-size: 12px;
        }
        & tbody {
            & .jira-links {
                word-break: break-word;
            }
        }
    }
    &.clients-table {
        table-layout: fixed;
        & .links-col {
            width: 100px;
        }
    }
    &.product-table {
        & .client-code-col {
            width: 120px;
        }
        & .name-col {
            width: 500px;
        }
        & .job-col {
            width: 80px;
        }
        & .status-col {
            width: 100px;
        }
        & .jira-col {
            width: 150px;
        }
        & .links-col {
            width: 200px;
        }

    }
}

/* Filter controls */
.widget-controls.filter-control {
    right: 25px;
}

.sppiner-vert-search {
    top: 5px;
}

/* Custom classes */
.width-250 {
    width: 250px;
}

.width-300 {
    width: 250px;
}

@keyframes spin {
    from {
        transform: rotateZ(0deg);
    }
    to {
        transform: rotateZ(360deg);
    }
}
.rotating {
    animation: spin 1s infinite linear;
}

/* input inline margin-box */
.box-inline {
    margin: 0px 10px 0px 0px;
    min-width: 105px;
}

/* invoiced button */

div.invoiced-label {
    color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    padding: 4px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
    background-color: $brand-success;
    border-color: #32c067;
    display: inline-block;
    margin-bottom: 0;
    font-weight: 300;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
}

/* filters styling */

.widget-filter {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
}

.inline-filter {
    display: inline-block;
    & .input-group {
        width: 100%;
        & .po-label {
            padding-top: 5px;
            margin-right: 5px;
        }
        & .select2 {
            width: 90%;
        }
    }
}

.select-with-remove {
    width: 84%;
    margin-right: 4px !important;
}

.user-details {
    td {
        background-color: #4e91ce;
        border-color: #3a85c8;
    }
}

.d-block {
    display: block;
}

.milestone-status-0 {
    background-color: #666;
}
.milestone-status-10 {
    background: #f2c34d;
}
.milestone-status-20 {
    background: #3ecd74;
}
.flat-input {
    background: none;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid gray;
    outline: none;
    max-width: 100%;
    option {
        color: black;
    }
}



@keyframes spin{
	to{
		transform:rotateZ(360deg);
	}
}
@keyframes spinner{
	to{
		transform:rotateZ(-360deg);
	}
}
.spin-container{
	display:flex;
	align-items:center;
	justify-content:center;
  .spinner{
	  display:flex;
	  border-radius:50%;
	  border-width:20px;
	  border-style:solid;
	  border-color:rgba(0,0,0,0);
	  position:absolute;
  }
  .spin-1{
	  border-right-color:$red-dark;
	  border-left-color:$red-dark;
	  width:300px;
	  height:300px;
	  animation:spin 1.1s linear infinite;
  }
  .spin-2{
	  border-right-color:$orange;
	  border-left-color:$orange;
	  width:200px;
	  height:200px;
	  animation:spinner 1.05s linear infinite;
  }
  .spin-3{
	  border-right-color:$blue;
	  border-left-color:$blue;
	  width:100px;
	  height:100px;
	  animation:spin 1s linear infinite;
  }
}
